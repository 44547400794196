<template>
  <div />
</template>

<script>
export default {
  name: 'EmailComponent',
  props: {
    roleId: {
      type: Number, 
      default: 0
    }
  },
  data() {
    return {
      flag: false
    };
  },
  created() {
    if(this.roleId === 4) this.$router.push('/layout/report/summary').catch(() => {})
    else if (window.history.state.marketId) {
      this.$router
        .push({
          name: 'ProductTracker',
          state: { countryId: window.history.state.marketId, device: window.history.state.device }
        })
        .catch(() => {});
    } else if (this.$route.params.mode && parseInt(this.$route.params.mode, 16) === 1) {
      this.$router
        .push({
          name: 'Registration',
          state: { searchRegistration: this.$route.params.id }
        })
        .catch(() => {});
    } else if(this.$route.params.mode && parseInt(this.$route.params.mode, 16) === 2 && ![3, 4, 6, 7, 14].includes(this.roleId)) {
      this.$router
        .push({
          name: 'RegulatoryUpdates',
          state: { serviceId: this.$route.params.id,
            service: 'Regulatory Watch'
           }
        })
        .catch(() => {});
    } else if(this.$route.params.mode && parseInt(this.$route.params.mode, 16) === 3 && ![3, 4, 6, 7, 14].includes(this.roleId)) {
       if(this.$route.query?.module) {
        this.$router
        .push({
          name: 'SmartDocsModules',
          params: { moduleId: this.$route.query.module},
          state: { searchDocument: this.$route.params.id, 
                    data: {
                      entityType: +this.$route.query?.entityType,
                      id: this.$route.query?.id
                    }
                }
        });
      }else {
      this.$router
        .push({
          name: 'Smart Docs',
          state: { searchDocument: this.$route.params.id, 
                    data: {
                      entityType: +this.$route.query?.entityType,
                      id: this.$route.query?.id
                    }
                  }
        })
        .catch(() => {});
      }
    } else this.$router.push('/layout/dashboard').catch(() => {});
  }
};
</script>
<style></style>
